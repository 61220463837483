import toast from 'react-hot-toast';
import {useAppDispatch, useAppSelector, useBreakPoint, useModal} from '@hooks';

import {
  Meeting,
  MeetingRoom,
  getCanUserAddRoomToExistingMeeting,
  getDefaultBuildingId,
  getMeetingRoomsByMeetingId,
  getMeetingRoomsGroupedByBuilding,
  getMeetingsByDate,
  getMeetingsLoadingStatusByDate,
  getUserDirectoryObjectId,
  getWorkdayByDate,
  updateMeeting,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {Button, ExpandMapButton, IconButton} from '@molecules';
import {BigMapModal, CreateEventManualRoomSelectionCard, LoaderTile, MeetingDetailsCard, Tile} from '@organisms';
import {Icon} from '@atoms';
import {addDays, parseISO} from 'date-fns';
import {sortAttendeesByLocation} from '@utils';
import {useTranslation} from 'react-i18next';
import {MeetingRoomInfoFooter} from './MeetingRoomInfoFooter';
import {MeetingRoomMapView} from './MeetingRoomMapView';
import {describeMeeting, getFormatedMeetingTime, selectMeetingsToShow} from '@lib/store/src/util';
import {trackEvent} from '@lib/infrastructure';
import {t} from 'i18next';

const UpcomingMeetingTile = ({meeting, time}: {meeting: Meeting; time: Date}) => {
  const {t} = useTranslation();
  const breakpoint = useBreakPoint();

  const addRoomLabel = 'Add room'; // TODO: translate

  const dispatch = useAppDispatch();

  // for handling clicks
  const {openModal, closeModal, setModalPages, setBigPages} = useModal();

  const meetingRooms = useAppSelector((state) => getMeetingRoomsByMeetingId(state, meeting.id));
  const location = meetingRooms.at(0);

  const startTime = new Date(meeting.startDateTime);
  const endTime = new Date(meeting.endDateTime);
  const {label, countdown} = describeMeeting(time, startTime, endTime, t);

  const timeLabel = getFormatedMeetingTime(meeting, t);

  const defaultBuildingId = useAppSelector(getDefaultBuildingId);
  const currentUserDirectoryObjectId = useAppSelector(getUserDirectoryObjectId);
  const currentUser = meeting.attendees.find(
    (attendee) => attendee.directoryObjectId === currentUserDirectoryObjectId,
  )!;

  const userWorkdayWhenMeetingStarts = useAppSelector((state) => getWorkdayByDate(state, meeting.startDateTime));

  const meetingRoomsByBuilding = useAppSelector((state) => getMeetingRoomsGroupedByBuilding(state, meeting.id));
  const attendeesGroupedByLocation = sortAttendeesByLocation(
    meeting.attendees,
    currentUser,
    defaultBuildingId,
    meetingRoomsByBuilding,
  );

  const canUserAddRoom = useAppSelector((state) => getCanUserAddRoomToExistingMeeting(state, meeting.id)) && !location;

  const inOfficeAttendees = attendeesGroupedByLocation
    .filter((g) => g.locationType === 'Local')
    .reduce(
      (acc, g) => ({...acc, count: acc.count + g.attendees.filter((a) => a.responseStatus !== 'Declined').length}),
      {
        icon: 'office' as const,
        count: 0,
        primary: !userWorkdayWhenMeetingStarts || userWorkdayWhenMeetingStarts?.status === 'OfficeDay',
      },
    );

  const remoteAttendees = attendeesGroupedByLocation
    .filter((g) => g.locationType === 'Remote')
    .reduce(
      (acc, g) => ({...acc, count: acc.count + g.attendees.filter((a) => a.responseStatus !== 'Declined').length}),
      {
        icon: 'remote' as const,
        count: 0,
        primary: false,
      },
    );

  const unknownAttendees = attendeesGroupedByLocation
    .filter((g) => g.locationType === 'Unknown')
    .reduce(
      (acc, g) => ({...acc, count: acc.count + g.attendees.filter((a) => a.responseStatus !== 'Declined').length}),
      {
        icon: 'locationUnknown' as const,
        count: 0,
        primary: false,
      },
    );

  const declinedAttendees = {
    primary: false,
    icon: 'noCalendar' as const,
    count: meeting.attendees.filter((a) => a.responseStatus === 'Declined').length,
  };

  const attendeeTiles = [inOfficeAttendees, remoteAttendees, declinedAttendees, unknownAttendees];
  const showTiles = breakpoint !== 'small' && breakpoint !== 'medium';

  const handleInfoClick = () => {
    const modalCard = (
      <MeetingDetailsCard
        date={meeting.startDateTime}
        meeting={meeting}
      />
    );
    trackEvent(`Home_UpcomingMeetingsTile__ViewDetails`);
    setModalPages([modalCard]);
    openModal();
  };

  const handleAddRoomClick = () => {
    setModalPages([
      <CreateEventManualRoomSelectionCard
        defaultBuildingId={defaultBuildingId}
        origin="CalendarOverview_AdHocRoom"
        endDateTime={meeting.endDateTime}
        onClose={closeModal}
        onSelect={async (value: MeetingRoom) => {
          const {success} = await dispatch(
            withAsyncThunkErrorHandling(() =>
              updateMeeting({
                origin: 'AddRoomToExistingMeeting',
                meetingId: meeting.id,
                systemTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                rooms: [...meetingRooms.map((room) => room.email), value.email],
              }),
            ),
          );
          if (success) {
            closeModal();
            toast.success(t('meeting:AddMeetingRoomToMeetingSuccessMessage', {roomName: value.displayName}));
          }
        }}
        selectedRooms={meetingRooms.map((room) => room.email)}
        startDateTime={meeting.startDateTime}
      />,
    ]);

    trackEvent('Home_UpcomingMeetingsTile__AddRoomClicked');
    openModal();
  };

  const expandMap = () => {
    if (!location) return;
    const mapView = (
      <BigMapModal
        title="Meeting room"
        main={
          <MeetingRoomMapView
            room={location}
            interactive={true}
          />
        }
        footer={<MeetingRoomInfoFooter room={location} />}
      />
    );

    trackEvent('Home_UpcomingMeetingsTile__ExpandMap', {
      buildingName: location.buildingName,
      floorName: location.floorName,
      roomName: location.displayName,
    });

    setModalPages([mapView]);
    setBigPages([mapView]);
    openModal();
  };

  return (
    <Tile
      title={label ?? undefined}
      titleNote={countdown ?? undefined}>
      {(location || canUserAddRoom) && (
        <div className="h-80 w-full rounded-xl bg-collaborative-blue-50 flex items-center justify-center relative">
          {location && (
            <>
              <MeetingRoomMapView room={location} />
              <ExpandMapButton
                onClick={expandMap}
                className={'absolute top-4 right-4'}
              />
            </>
          )}

          {!location && canUserAddRoom && (
            <Button
              aria-label={t('screen:NewEventRoomSelection')}
              button="tertiary"
              iconRight="plus"
              onClick={handleAddRoomClick}>
              {addRoomLabel}
            </Button>
          )}
        </div>
      )}
      <div
        className="flex gap-4 w-full cursor-pointer"
        onClick={handleInfoClick}>
        <div className="flex justify-center items-center size-12 bg-beige-500* rounded-lg flex-none">
          <Icon
            icon="calendar"
            size={'22px'}
          />
        </div>
        <div className="flex flex-col mr-auto min-w-0">
          <strong>{timeLabel}</strong>
          <p className="truncate">{meeting.title}</p>
          {location && (
            <p>
              {location.displayName}, {location.floorName}
            </p>
          )}
        </div>

        {showTiles && (
          <div className="h-[40px] flex items-center gap-2 flex-none">
            {attendeeTiles.map((t) => (
              <div
                key={t.icon}
                className={`flex rounded-md items-center  px-2 py-1 gap-1 ${
                  t.primary ? 'bg-energizing-yellow-500*' : 'bg-beige-400'
                }`}>
                <Icon
                  className="flex-none w-[20px]"
                  icon={t.icon}
                  size={'20px'}
                />
                <p className="mt-0.5">{t.count}</p>
              </div>
            ))}
          </div>
        )}
        <div>
          <IconButton
            icon="info"
            iconButton="tertiary"
            aria-label={t('GetInformationAboutYourUpcomingMeeting')}
            onClick={(e) => {
              e.stopPropagation();
              handleInfoClick();
            }}
            className="rounded-lg"
          />
        </div>
      </div>
    </Tile>
  );
};

export const UpcomingMeetingsTile = ({date, time}: {date: Date; time: Date}) => {
  const meetings = useAppSelector((state) => getMeetingsByDate(state, date));
  const tomorrowMeetings = useAppSelector((state) => getMeetingsByDate(state, addDays(date, 1)));
  const meetingsLoadingStatus = useAppSelector((state) => getMeetingsLoadingStatusByDate(state, date));

  if (meetingsLoadingStatus !== 'Loaded') {
    return <LoaderTile />;
  }

  const visibleMeetings = selectMeetingsToShow([...meetings, ...tomorrowMeetings], time, {
    allDayUntilHour: 10,
    hideCurrentIfNextStartsWithinMinutes: 10,
  });

  return visibleMeetings.length ? (
    visibleMeetings.map((m, i) => (
      /* TODO: why does this warn for non-unique key? */
      <UpcomingMeetingTile
        key={`TILE_${m.id}`}
        time={time}
        meeting={m}
      />
    ))
  ) : (
    <Tile title="Upcoming Meetings">
      <div className="bg-beige-500* flex flex-col justify-center items-center w-full rounded-lg text-center py-9 px-4">
        <p>{t('meeting:NoMeetingsForToday')}</p>
      </div>
    </Tile>
  );
};
