import {AreaWithSensorInfo, type MeetingRoom} from '@lib/store';
import {HaNExploreList, HaNRoomList, HaNWorkspacesList} from '@organisms';
import {isArea, isMeetingRoom, useTabByPathname} from '../helpers';
import {useFeatureToggle} from '@hooks';
import {ExploreState} from 'src/components/pages/HereAndNow/useExploreState';
import {FacilityResource} from '@lib/store/src/data/buildingResources';

export type HaNVerticalListContentProps = {
  rooms: MeetingRoom[];
  areas: AreaWithSensorInfo[];
  exploreState: ExploreState;
  selectedObject: MeetingRoom | AreaWithSensorInfo | FacilityResource | null;
  onClick: (entity: MeetingRoom | AreaWithSensorInfo | FacilityResource) => void;
  onHover: (spotlightId: string | null) => void;
  startDateTime: string;
  endDateTime: string;
};

export const HaNVerticalListContent = ({
  rooms,
  areas,
  exploreState,
  onClick,
  selectedObject,
  onHover,
  endDateTime,
  startDateTime,
}: HaNVerticalListContentProps) => {
  const tab = useTabByPathname();

  const {ExploreOnHereAndNow} = useFeatureToggle();
  const {
    WorkspacesOnHereAndNow: isWorkspacesOnHereAndNowEnabled,
    HybridMeetingsCalendarView: isHybridMeetingsCalendarViewEnabled,
  } = useFeatureToggle();

  if (tab === 'explore' && ExploreOnHereAndNow) {
    return (
      <HaNExploreList
        exploreState={exploreState}
        onClick={onClick}
      />
    );
  }

  if (
    (tab === 'rooms' || (!isWorkspacesOnHereAndNowEnabled && !tab)) &&
    (selectedObject === null || isMeetingRoom(selectedObject))
  ) {
    return (
      <HaNRoomList
        buildingId={exploreState.buildingId}
        onClick={onClick}
        rooms={rooms}
        selectedRoom={selectedObject}
        onHover={onHover}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
      />
    );
  } else if (tab === 'workspaces' && (selectedObject === null || isArea(selectedObject))) {
    return (
      <HaNWorkspacesList
        areas={areas}
        onClick={onClick}
        onHover={onHover}
        selectedWorkspace={selectedObject}
      />
    );
  }
  return null;
};
