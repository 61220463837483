import {trackEvent} from '@lib/infrastructure';
import {MapType} from '@lib/store';
import {useEffect, useState} from 'react';

export type MapTypePreference = MapType;

const DEFAULT_PREFERENCE: MapTypePreference = '2d';
const MAP_PREFERENCE_KEY = 'MAPIQ_MAP_TYPE_PREFERENCE';

const getFromStorage = (key: string): MapTypePreference => {
  const storedValue = localStorage.getItem(key);

  if (storedValue === '2d') return '2d';
  if (storedValue === '3d') return '3d';

  return DEFAULT_PREFERENCE;
};

const writeToStorage = (key: string, pref: MapTypePreference) => {
  localStorage.setItem(key, pref);
};

export const useGlobalMapTypePreference = (namespace = '') => {
  const key = namespace ? `${MAP_PREFERENCE_KEY}_${namespace}` : MAP_PREFERENCE_KEY;
  const [preference, setPreference] = useState(getFromStorage(key));

  useEffect(
    function syncToLocalStorage() {
      writeToStorage(key, preference);
      window.dispatchEvent(new Event('storage'));
    },
    [key, preference],
  );

  useEffect(
    function setupSyncFromLocalStorage() {
      const syncFromLocalStorage = () => {
        const value = getFromStorage(key);
        setPreference(value);
      };

      window.addEventListener('storage', syncFromLocalStorage);

      return function disposeStorageListener() {
        window.removeEventListener('storage', syncFromLocalStorage);
      };
    },
    [key],
  );

  const trackedSetPreference = (newPreference: MapTypePreference) => {
    trackEvent('LocalRenderModePreferenceChange', {
      namespace: namespace || 'global',
      toType: newPreference,
    });

    setPreference(newPreference);
  };

  return [preference, trackedSetPreference] as const;
};
