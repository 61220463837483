import {useAppSelector, useBreakPoint, useFeatureToggle} from '@hooks';
import {
  getEnabledFeaturesLoadingStatus,
  getUser,
  getUserInitials,
  useHomeBuildingId,
  useHomePageSections,
  useHomeTime,
} from '@lib/store';
import {Avatar} from '@molecules';
import {FlexCol, FlexRow, H1} from '@quarks';
import {addDays, format, isSameDay, setHours, setMinutes, startOfDay} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {Sidebar} from './Components/Sidebar/Sidebar';
import {
  AvailableRoomsTile,
  AvailableWorkspacesTile,
  ConnectionsTile,
  DiscoverTheOfficeTile,
  MeetingConsentTile,
  NextWorkdaysTile,
  UpcomingMeetingsTile,
  WorkdayTile,
} from '@organisms';
import styled from 'styled-components';
import {BREAKPOINTS} from '@constants';
import {Loader} from '@atoms';
import {NavLink} from 'react-router-dom';
import {cn} from '@utils';

export const Home = () => {
  const enabledFeatures = useFeatureToggle();
  const {AppHomePage_DebugTime} = enabledFeatures;

  // Use automatically updating time. Note: using this will cause repeated renders of the whole component every tick
  const {time, timeDisplay, todayDate, tomorrowDate, setDebugTime, usesDebugTime, partOfDay} = useHomeTime(5000);

  const flagsLoading = useAppSelector(getEnabledFeaturesLoadingStatus) !== 'Loaded';
  const todayKey = format(todayDate, 'yyyy-MM-dd');

  const referenceDate = partOfDay === 'Evening' ? tomorrowDate : todayDate;
  const referenceDateKey = format(referenceDate, 'yyyy-MM-dd');
  const sections = useHomePageSections(referenceDateKey, partOfDay, enabledFeatures);

  // In practice, we will hide sensor-based data in the evenings, but just to be sure
  // we will create two building ids
  const buildingIdToday = useHomeBuildingId(todayKey);
  const buildingIdReferenceDate = useHomeBuildingId(referenceDateKey);

  // No point to continue if we're still waiting for feature flags
  if (flagsLoading) return null;
  // TODO: some memoization might help to prevent re-rendering of tiles on every tick
  //       at the same time, we might not need to worry about it if we make sure we don't
  //       do slow or weird things in these sections
  const newTiles = sections.map((s) => {
    // If these tiles had the same props, this would be a bit cleaner
    switch (s.componentKey) {
      case 'WorkdayTile':
        return (
          <WorkdayTile
            date={referenceDate}
            key={s.componentKey}
          />
        );
      case 'UpcomingMeetingsTile':
        return (
          <UpcomingMeetingsTile
            date={referenceDate}
            time={time}
            key={s.componentKey}
          />
        );
      case 'MeetingConsentTile':
        return <MeetingConsentTile key={s.componentKey} />;
      case 'AvailableRoomsTile':
        return (
          <AvailableRoomsTile
            time={time}
            buildingId={buildingIdToday}
            key={s.componentKey}
          />
        );
      case 'AvailableWorkspacesTile':
        return (
          <AvailableWorkspacesTile
            time={time}
            buildingId={buildingIdToday}
            key={s.componentKey}
          />
        );
      case 'DiscoverTheOfficeTile':
        return (
          <DiscoverTheOfficeTile
            key={s.componentKey}
            buildingId={buildingIdReferenceDate}
          />
        );
      case 'NextOfficeDaysTile':
        return (
          <NextWorkdaysTile
            key={s.componentKey}
            date={referenceDate}
          />
        );
      case 'ConnectionsTile':
        return (
          <ConnectionsTile
            key={s.componentKey}
            date={referenceDate}
          />
        );
      case 'ExtrasTile':
        return null;
    }
  });

  return (
    <HomeLayoutWrapper>
      <HomeLayout>
        {/* Header */}
        <FixedHeader>
          <Greeting
            time={time}
            referenceDate={referenceDate}
          />

          {AppHomePage_DebugTime ? (
            <FlexRow
              className="absolute top-0 right-0 p-2 border-black bg-slate-400 rounded-b-lg"
              alignItems="center"
              gap={8}>
              <input
                readOnly={!usesDebugTime}
                onChange={(e) => {
                  const [h, m] = e.target.value.split(':').map(Number);
                  if (typeof h !== 'number' || typeof m !== 'number') return;

                  const today = startOfDay(time);

                  const atHour = setHours(today, h);
                  const atMinute = setMinutes(atHour, m);

                  setDebugTime(atMinute);
                }}
                type="time"
                value={timeDisplay}
              />
              <label>
                <input
                  type="checkbox"
                  defaultChecked={usesDebugTime}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDebugTime(time);
                    } else {
                      setDebugTime(null);
                    }
                  }}
                />
                Override
              </label>
            </FlexRow>
          ) : null}
        </FixedHeader>

        <HomeBody
          as="main"
          justifyContent="flex-start"
          gap={16}>
          {newTiles}
          {newTiles.length === 0 && (
            <FlexRow justifyContent="center">
              <Loader />
            </FlexRow>
          )}
        </HomeBody>
        <HomeSidebar
          gap={32}
          justifyContent="flex-start">
          <Sidebar date={referenceDate} />
        </HomeSidebar>
        {/* Body */}
      </HomeLayout>
    </HomeLayoutWrapper>
  );
};

const Greeting = ({time, referenceDate}: {time: Date; referenceDate: Date}) => {
  const {t} = useTranslation();
  const user = useAppSelector(getUser);
  const userInitials = useAppSelector(getUserInitials);
  const bp = useBreakPoint();

  const tomorrow = addDays(time, 1);

  const title = isSameDay(time, referenceDate)
    ? t('Today')
    : isSameDay(tomorrow, referenceDate)
    ? t('Tomorrow')
    : // This case should not be hit unless we decide the page will show stuff
      // further in the future (e.g. show Monday on a Satruday)
      format(referenceDate, 'dd MMMM');

  return (
    <H1
      sm={{
        gridArea: 'title',
      }}
      className={cn('flex w-full items-center gap-4 text-black', {
        'flex-row-reverse': bp === 'small',
        'justify-between': bp === 'small',
      })}>
      <NavLink to="profile">
        <Avatar
          shouldShowCheckIn={true}
          size={bp === 'small' ? 'medium' : 'large'}
          user={{...user, initials: userInitials}}
        />
      </NavLink>
      {title}
    </H1>
  );
};

const HomeLayoutWrapper = styled.div`
  background: #fdfaf7;
  grid-area: canvas;

  ${BREAKPOINTS.small`
    grid-row: header / canvas;
  `}
`;

const HomeLayout = styled('article')`
  --verticalOffset: 60px; // This one should match the small header
  --headerHeight: 90px;
  --headerFadeHeight: 20px;

  --mainWidth: 800px;
  --sideWidth: 320px;
  --gutterWidth: 2rem;
  --homeLayoutWidth: calc(var(--mainWidth) + var(--sideWidth) + var(--gutterWidth));

  height: calc(100vh - var(--verticalOffset));
  max-width: var(--homeLayoutWidth);
  padding: 0 var(--gutterWidth);
  margin: 0 auto;

  position: relative;
  display: grid;

  grid-template-rows: var(--headerHeight) 1fr;
  grid-template-columns: 2.5fr 1fr;
  grid-template-areas:
    'header-main header-sidebar'
    'main sidebar';
  column-gap: var(--gutterWidth);

  justify-content: center;

  ${BREAKPOINTS.large`
    --verticalOffset: 100px;
    grid-row: header / canvas;

    grid-template-rows: var(--verticalOffset) auto auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      'header-main'
      'main'
      'sidebar';

    grid-column-gap: 0;
    grid-row-gap: 0 1rem;
    max-width: 1000px;
    height: auto;
  `}

  ${BREAKPOINTS.small`
    --verticalOffset: 60px;
    padding: 0;
    max-width: unset;
  `}
`;

const FixedHeader = styled('header')`
  position: absolute;
  z-index: 1;
  grid-column: header-main / header-sidebar;
  padding-top: 1rem;

  width: 100%;
  height: var(--headerHeight);

  background: linear-gradient(
    180deg,
    #fdfaf7 calc(var(--headerHeight) - var(--headerFadeHeight)),
    rgba(255, 255, 255, 0) 100%
  );

  display: flex;
  align-items: center;

  ${BREAKPOINTS.large`
    position: relative;
    background: transparent;
  `}

  ${BREAKPOINTS.small`
    padding: 0 1rem;
    height: var(--verticalOffset);

    background: white;
    border-bottom: 1px solid #e8e8e8;
  `}
`;

const HomeBody = styled(FlexCol)`
  grid-row: header-main / main;
  padding-top: var(--headerHeight);

  // For scrollbar
  overflow-y: auto;
  padding-right: 20px;

  // Space at bottom of scroll container
  padding-bottom: 100px;

  ${BREAKPOINTS.large`
    grid-row: unset;
    grid-area: main;
    padding: 0;
    padding-bottom: 4rem;
  `}

  ${BREAKPOINTS.small`
    grid-row: unset;
    grid-area: main;
    padding-bottom: 1rem;
  `}
`;

const HomeSidebar = styled(FlexCol)`
  grid-row: header-sidebar / sidebar;
  padding-top: var(--headerHeight);

  // For scrollbar
  overflow-y: auto;
  padding-right: 20px;

  // Space at bottom of scroll container
  padding-bottom: 100px;

  ${BREAKPOINTS.large`
    grid-row: unset;
    grid-area: sidebar;
    padding: 0;
  `}
`;
