import {useTranslation} from 'react-i18next';
import {Icon} from '@atoms';
import {useAppSelector, useBreakPoint, useModal} from '@hooks';
import {getParkingReservationByDate, getParkingSettings, getLicensePlates, getIsLoadingParkingLots} from '@lib/store';
import {Button} from '@molecules';
import {ParkingSelectionCard} from '@organisms';
import {FlexCol, FlexRow, H4, P} from '@quarks';
import {LicensePlatesModalPage} from '../../profile/licensePlates/LicensePlatesModalPage/LicensePlatesModalPage';
import {ParkingSkeletonLoader} from './ParkingSkeletonLoader';

type Props = {
  dateKey: string;
};

export const ParkingReservationRow = ({dateKey}: Props) => {
  const {t} = useTranslation();
  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, dateKey)) ?? null;
  const isLoadingParkingLots = useAppSelector(getIsLoadingParkingLots);
  const parkingSettings = useAppSelector(getParkingSettings);

  const licensePlates = useAppSelector(getLicensePlates);
  const breakPoint = useBreakPoint();
  const {openModal, setModalPages} = useModal();

  const isLicensePlateMandatory = parkingSettings?.licensePlateIsRequired ?? false;
  const shouldOpenLicensePlateModal = isLicensePlateMandatory && !licensePlates?.length;
  const smallBreakPoint = breakPoint === 'small';

  function handleClickChangeParking() {
    if (shouldOpenLicensePlateModal) {
      setModalPages([<LicensePlatesModalPage date={dateKey} />]);
    } else {
      setModalPages([<ParkingSelectionCard date={dateKey} />]);
    }

    openModal();
  }
  return isLoadingParkingLots ? (
    <ParkingSkeletonLoader />
  ) : (
    <>
      <FlexRow
        alignItems="center"
        justifyContent="space-between"
        gap={16}
        aria-label={t('ChangeOrBookParking')}
        onClick={smallBreakPoint ? handleClickChangeParking : () => null}
        className={`${!smallBreakPoint ? 'border-t-1 border-mapiq-black-150 pt-4' : ''}`}>
        <FlexRow
          gap={16}
          alignItems="center">
          <FlexCol
            justifyContent="center"
            alignItems="center"
            className=" bg-beige-500* h-12 w-12 rounded-lg shrink-0">
            <Icon
              icon="parking"
              size="24px"
            />
          </FlexCol>
          <FlexCol>
            <H4>{parkingReservation?.parkingLotName ?? t('parking:ParkingReservationButtonText')}</H4>
            <P>{parkingReservation?.zoneName}</P>
          </FlexCol>
        </FlexRow>
        {smallBreakPoint ? (
          <Icon
            icon="caretRight"
            size="20px"
            weight="light"
            color="grey"
            className="flex-shrink-0"
          />
        ) : (
          <Button
            button={'tertiary'}
            onClick={handleClickChangeParking}>
            {parkingReservation ? t('Change') : t('Book')}
          </Button>
        )}
      </FlexRow>
    </>
  );
};
