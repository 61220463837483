import {Span} from '@quarks';
import {Date} from '@atoms';
import {UICalendarDay} from '@lib/store';
import {useTheme} from 'styled-components';
import {formatDate, isBeforeToday} from '@lib/utils';
import {isSameDay, parseISO, startOfToday} from 'date-fns';
import {useParams} from 'react-router-dom';
import {DateWrapper, LinkWrapper} from './styles';
import {useFeatureToggle} from '@hooks';

export const CalendarHorizontalWeekDay = ({day}: {day: UICalendarDay}) => {
  const theme = useTheme();
  const {AppHomePage} = useFeatureToggle();
  const dayOverviewLink = AppHomePage
    ? `/calendar/${formatDate(parseISO(day.date), 'yyyy-MM-dd', 'en')}`
    : `${formatDate(parseISO(day.date), 'yyyy-MM-dd', 'en')}`;

  const isPast = isBeforeToday(parseISO(day.date), startOfToday());

  const params = useParams();
  const activeDate = params.date ?? startOfToday().toISOString();
  const isActive = isSameDay(parseISO(day.date), parseISO(activeDate));

  return (
    <LinkWrapper
      $isActive={isActive}
      $isPast={isPast}
      $isToday={day.isToday}
      tabIndex={isPast ? -1 : 0}
      to={dayOverviewLink}
      onClick={(e) => {
        if (isPast) e.preventDefault();
      }}>
      <DateWrapper
        $isActive={isActive}
        $isToday={day.isToday}>
        <Span
          color={day.isToday ? theme.card.beige500.color : !isPast ? theme.text.color.subtitle : theme.disabled.color}
          fontSize={'sm'}
          fontWeight="regular">
          <Date
            date={day.date}
            format="EEEEE"
          />
        </Span>
        <Span
          color={day.isToday ? theme.card.beige500.color : !isPast ? theme.text.color.body : theme.disabled.color}
          fontWeight="bold"
          fontSize="md">
          <Date
            date={day.date}
            format="d"
          />
        </Span>
      </DateWrapper>
    </LinkWrapper>
  );
};
